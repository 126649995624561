import i18n from "@/i18n";
import { markRaw, toRaw } from "vue";
import {
  Plus,
  BottomLeft,
  TopRight,
  Search,
  Download,
} from "@element-plus/icons-vue";
import api from "@/api";
import moment from "moment";
import { getUploadUrl } from "../../utils/tools";
const { t } = i18n.global;
export default {
  tabsLabel: {
    tabA: t("map.yonghurizhi"),
    tabB: t("map.xiangqingtongji"),
  },
  tableCols: [
    { name: t("report.zh"), filed: "zh" },
    { name: t("report.xm"), filed: "xm" },
    { name: t("report.ssgs"), filed: "cName" },
    { name: t("report.IP"), filed: "ip" },
    { name: t("report.cph"), filed: "vPlate" },
    { name: t("report.cz"), filed: "lOrder" },
    { name: t("report.fwd"), filed: "lSource" },
    { name: t("report.czxw"), filed: "lContent" },
    { name: t("report.czsj"), filed: "sj" },
    { name: t("report.xq"), filed: "detail" },
  ],
  tableOps: [
    // {
    //   opType: "btn",
    //   icon: markRaw(Search),
    //   name: t("commKey.DETAIL"),
    //   type: "success",
    //   click: (row, callback) => {
    //     callback({ type: "detail", row: row });
    //   },
    // },
  ],
  searchCols: [
    {
      type: "msel",
      key: "userids_ay",
      val: [],
      title: t("map.user"),
      data: [],
    },
    {
      type: "sel",
      key: "type",
      val: "",
      title: t("map.fanyi104"),
      data: [
        { name: "login", value: "login" },
        { name: "logout", value: "logout" },
        { name: "add", value: "add" },
        { name: "update", value: "update" },
        { name: "delete", value: "delete" },
      ],
    },
    {
      type: "sel",
      key: "login_type",
      val: "",
      title: t("map.fangwenduan"),
      data: [
        { name: "web", value: "web" },
        { name: "Mobile", value: "Mobile" },
      ],
    },
    {
      type: "ipt",
      key: "content_value",
      val: "",
      title: t("map.table_nr"),
    },
    // {
    //   type: "tsel",
    //   ref: "refTree",
    //   key: "devids",
    //   val: [],
    //   title: t("commKey.keyname"),
    //   props: {
    //     icon: "icon",
    //     value: "gps_id",
    //     label: "nodeName",
    //     children: "children",
    //   },
    // },
    {
      type: "sel",
      key: "dateType",
      val: 1,
      title: "",
      data: [
        { name: t("map.jintian"), value: 1 },
        { name: t("map.zuotian"), value: 2 },
        { name: t("map.benzhou"), value: 3 },
        { name: t("map.shangzhou"), value: 4 },
        { name: t("map.benyue"), value: 5 },
        { name: t("map.shangyue"), value: 6 },
        { name: t("map.zidingyi"), value: 7 },
      ],
      change: (val, callback) => {
        callback({ type: "change_time", evt: val });
        // console.log(obj);
      },
    },
    {
      type: "datetime",
      key: "begintime",
      val: moment().format("YYYY-MM-DD 00:00:00"),
      title: t("commKey.STARTDATE"),
    },
    {
      type: "datetime",
      key: "endtime",
      val: moment().format("YYYY-MM-DD 23:59:59"),
      title: t("commKey.ENDDATE"),
    },
    // {
    //   type: "dtr",
    //   key: "times",
    //   val: [
    //     moment().format("YYYY-MM-DD 00:00:00"),
    //     moment().format("YYYY-MM-DD 23:59:59"),
    //   ],
    //   title_s: t("commKey.STARTDATE"),
    //   title_e: t("commKey.ENDDATE"),
    // },

    {
      type: "btn",
      btnType: "success",
      icon: markRaw(Search),
      title: t("commKey.Search"),
      click: (callback) => {
        callback({ type: "search" });
      },
    },
    // {
    //   type: "btn",
    //   btnType: "",
    //   icon: markRaw(Download),
    //   title: t("commKey.EXCEL"),
    //   click: (callback) => {
    //     callback({ type: "download" });
    //   },
    // },
    // {
    //   type: "btn",
    //   btnType: "warning",
    //   icon: markRaw(Download),
    //   title: t("commKey.PDF"),
    //   click: (callback) => {
    //     callback({ type: "export" });
    //   },
    // },
  ],
  tableDetailCols: [
    { name: t("report.cph"), filed: "cph" },
    { name: t("report.sbh"), filed: "sbh" },
    { name: t("report.sim"), filed: "sim" },
    { name: t("report.ssgs"), filed: "ssgs" },
    { name: t("report.dwsj"), filed: "dwsj" },
    { name: t("report.dszt"), filed: "dszt" },
    { name: t("report.dswz"), filed: "dswz" },
  ],
};
