<template>
  <div class="gps-box">
    <div class="gps-search">
      <div class="gps-item" v-for="(it, idx) in searchCols" :key="`a` + idx">
        <el-tree-select
          :ref="it.ref"
          v-if="it.type == 'tsel'"
          v-model="it.val"
          :data="it.data"
          :render-after-expand="false"
          show-checkbox
          :placeholder="it.title"
          :props="it.props"
          multiple
          collapse-tags
          collapse-tags-tooltip
          filterable
          clearable
          :default-expand-all="true"
        >
          <template #default="{ node, data }">
            <span class="prefix"
              ><img :src="data.icon" alt="" style="width: 17px; height: 17px"
            /></span>
            <span @dblclick="nodeClick(data)">{{ node.label }}</span>
          </template>
        </el-tree-select>
        <el-input
          v-if="it.type == 'ipt'"
          v-model="it.val"
          :placeholder="it.title"
          clearable
        ></el-input>
        <el-date-picker
          clearable
          v-if="it.type == 'datetime'"
          v-model="it.val"
          type="datetime"
          :placeholder="it.title"
          value-format="YYYY-MM-DD hh:mm:ss"
        />
        <el-date-picker
          clearable
          v-if="it.type == 'dtr'"
          v-model="it.val"
          type="datetimerange"
          range-separator="To"
          :start-placeholder="it.title_s"
          :end-placeholder="it.title_e"
          value-format="YYYY-MM-DD HH:mm:ss"
        />
        <el-select
          clearable
          v-if="it.type == 'sel'"
          v-model="it.val"
          :placeholder="it.title"
          @change="it.change && it.change($event, callbackFun)"
        >
          <el-option
            v-for="item in it.data"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
        <el-select
          clearable
          v-if="it.type == 'msel'"
          multiple
          v-model="it.val"
          :placeholder="it.title"
        >
          <el-option
            v-for="item in it.data"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
        <el-button
          v-if="it.type == 'btn'"
          @click="it.click && it.click(callbackFun)"
          :type="it.btnType"
          :icon="it.icon"
          round
          >{{ it.title }}</el-button
        >
        <el-upload
          v-if="it.type == 'upload'"
          class="upload-demo"
          :action="it.url"
          name="excelFile"
          :data="uploadData"
          :limit="1"
          :on-success="handleUpload"
        >
          <el-button :type="it.btnType" :icon="it.icon" round>{{
            it.title
          }}</el-button>
        </el-upload>
      </div>
    </div>
    <div class="aas">
      <div class="gps-body">
        <el-table :data="tableData" style="width: 100%" :height="tableHeight">
          <el-table-column
            :label="cls.name"
            :width="cls.width"
            v-for="cls in tableCols"
            :key="cls.filed"
          >
            <template #default="{ row }">
              <span
                v-if="cls.render"
                v-html="cls.render(row)"
                @click="cls.click && cls.click(row, callbackFun)"
              >
              </span>
              <span v-else @click="cls.click && cls.click(row, callbackFun)">{{
                row[cls.filed]
              }}</span>
            </template>
          </el-table-column>

          <el-table-column
            fixed="left"
            :label="$t('commKey.MINGXI')"
            width="170px"
            v-if="tableOps.length > 0"
          >
            <template #default="{ row }">
              <span class="gps-span" v-for="(op, idx) in tableOps" :key="idx">
                <el-button
                  v-if="op.opType == 'btn'"
                  :icon="op.icon"
                  round
                  :type="op.type"
                  @click="op.click && op.click(row, callbackFun)"
                  >{{ op.name }}</el-button
                >
                <el-popconfirm
                  :title="$t('commKey.CONFIRMTITLE')"
                  v-if="op.opType == 'del'"
                  @confirm="op.click && op.click(row, callbackFun)"
                >
                  <template #reference>
                    <el-button :icon="op.icon" round :type="op.type">{{
                      op.name
                    }}</el-button>
                  </template>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="gps-page">
        <el-pagination
          :currentPage="query.page"
          :page-size="query.rp"
          :page-sizes="[20, 50, 100, 200, 300, 400]"
          :disabled="disabled"
          :background="background"
          layout="total, prev, pager, next"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  toRefs,
  defineExpose,
  reactive,
  getCurrentInstance,
  watch,
  computed,
  defineEmits,
  toRaw,
  onMounted,
} from "vue";
import { useI18n } from "vue-i18n";
import signObj from "../../../utils/upload-sign";
const { proxy } = getCurrentInstance();
// const { $api } = proxy.appContext.config.globalProperties;
const { t } = useI18n();
const props = defineProps({
  // tabsLabel: {
  //   type: Object,
  //   default: () => {},
  // },
  searchCols: {
    type: Array,
    default: () => {},
  },
  tableData: {
    type: Array,
    default: () => {},
  },
  tableCols: {
    type: Array,
    default: () => {},
  },
  tableOps: {
    type: Array,
    default: () => {},
  },
  query: {
    type: Object,
    default: () => {},
  },
  total: {
    type: Number,
    default: 0,
  },
  // tableDetailCols: {
  //   type: Array,
  //   default: () => {},
  // },
  // queryDetail: {
  //   type: Object,
  //   default: () => {},
  // },
  // tableDetailData: {
  //   type: Array,
  //   default: () => {},
  // },
  // totalDetail: {
  //   type: Number,
  //   default: 0,
  // },
  // tabsName: {
  //   type: String,
  //   default: "list",
  // },
});

const background = ref(false);
const disabled = ref(false);
// const disabledDetail = ref(false);
// const backgroundDetail = ref(false);

const emit = defineEmits([
  "callbackEvent",
  "handleCurrentChange",
  "handleCurrentChangeDetail",
  "tabsActiveNameChange",
]);

const handleSizeChange = (val) => {
  console.log(`${val} items per page`);
};
const handleCurrentChange = (val) => {
  emit("handleCurrentChange", val);
};

const handleSizeChangeDetail = (val) => {
  console.log(`${val} items per page`);
};
const handleCurrentChangeDetail = (val) => {
  emit("handleCurrentChangeDetail", val);
};

const callbackFun = (obj) => {
  let attr_search = toRaw(props.searchCols);
  let attr = {};
  attr_search.forEach((it) => {
    if (it.type != "btn") {
      switch (it.type) {
        case "dtr":
        case "msel":
          if (it.key) attr[it.key] = it.val.join(",");
          break;
        case "tsel":
          if (it.key) {
            // let data_map = new Map();
            // it.data.forEach((it_it) => {
            //   data_map.set(it_it.gps_id, it_it);
            // });
            // // console.log(data_map);
            // let data_out = [];
            // it.val.forEach((it_k) => {
            //   if (data_map.has(it_k)) {
            //     data_out.push(data_map.get(it_k));
            //   }
            // });
            attr[it.key] = toRaw(proxy.$refs[it.ref][0].getCheckedNodes());
          }
          // if (it.key) attr[it.key] = it.val;
          break;

        default:
          if (it.key) attr[it.key] = it.val;
          break;
      }
    }
  });

  obj.search = attr;

  emit("callbackEvent", obj);
};

const uploadData = reactive(signObj);
const handleUpload = (response, uploadFile, uploadFiles) => {
  emit("callbackEvent", Object.assign({ type: "upload" }, response));
};

const get_heigth = () => {
  let h =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  return parseFloat(h) - 192;
};
const tableHeight = ref(300);
onMounted(() => {
  tableHeight.value = get_heigth();

  window.onresize = () => {
    tableHeight.value = get_heigth();
  };
});

//////////////////////////////////
//const activeName = ref("list");

// const handleClick = (tab, event) => {
//   console.log(tab.paneName, event, activeName.value);
//   emit("tabsActiveNameChange", tab.paneName);
// };
// watch(
//   () => props.tabsName,
//   (val) => {
//     console.log("watch", val);
//     activeName.value = val;
//   }
// );
</script>

<style lang="scss" scoped>
::v-deep(.el-table .cell .gps-span) {
  // display: inline-block;
  margin-right: 5px;
}
.gps-box {
  box-sizing: border-box;
  padding: 0px;
  .gps-search {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    margin-top: 10px;
    //border:1px solid #ccc;
    .gps-item {
      box-sizing: border-box;
      padding-right: 10px;
    }
  }
}
::v-deep .el-tabs {
  //border:1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  border-top: none;
}
::v-deep .el-tabs__nav-wrap {
  //margin:10px
}
::v-deep .el-table--fit {
  background: #fafafa;
  border: 1px solid #eee;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
::v-deep .el-tabs__item {
  background: #eee;
  color: #000 !important;
  border-radius: 30px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #e0e6f1;
  margin-right: 10px;
  margin-top: 5px;
}
::v-deep .el-tabs__item.is-active {
  border-radius: 30px;
}
::v-deep .el-input--small .el-input__wrapper {
  height: 30px !important;
}
::v-deep .aas {
  margin-top: 10px;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
}
::v-deep .el-table .cell span{font-weight:normal !important}
</style>
<style>
.el-select-dropdown__wrap {
  max-height: 550px !important;
}
</style>
