<template>
  <div class="gps-car">
    <view-grid
      :tableCols="tableCols"
      :tableOps="tableOps"
      :query="query"
      :tableData="tableData"
      :total="total"
      :searchCols="searchCols"
      @callbackEvent="callbackEvent"
      @handleCurrentChange="handleCurrentChange"
    ></view-grid>
  </div>
</template>

<script setup>
import ViewGrid from "./components/YhViewGrid.vue";
import {
  ref,
  defineAsyncComponent,
  watch,
  getCurrentInstance,
  shallowRef,
  reactive,
  onMounted,
  // markRaw,
  // toRef,
  inject,
  toRaw,
} from "vue";
// import {
//   Plus,
//   Delete,
//   BottomLeft,
//   TopRight,
//   Search,
//   Download,
//   Edit,
// } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import Tools from "./YhxwData";
import moment from "moment";
import { ElNotification } from "element-plus";
const { t } = useI18n();
const refAnyCom = ref();
let { proxy } = getCurrentInstance();

//表格显示的列
const tableCols = ref(Tools.tableCols);
//表格操作列
const tableOps = ref(Tools.tableOps);
//表单操作
const formArray = reactive(Tools.formCols);
const row = {};
const curr_row = reactive(row);

const total = ref(0);

const handleSizeChange = (val) => {
  console.log(`${val} items per page`);
};
const handleCurrentChange = (val) => {
  query.page = val;
  getList();
};

//查询列
const searchCols = ref(Tools.searchCols);

const tableData = ref([]);
const query = reactive({
  rp: 25,
  page: 1,
  devids: "",
  begintime: "",
  endtime: "",
});

const getList = () => {
  proxy.$api.search_yhxw_detail(query).then((res) => {
    tableData.value = res.data.data;
    total.value = res.data.pagination.totalRecords;
  });
};
const init = () => {
  proxy.$api.sys_users_grids({ rp: 1000, page: 1 }).then((res) => {
    console.log(res.data);
    let items = res.data.data;
    if (items && items.length > 0) {
      let ay = [];
      items.forEach((it) => {
        ay.push({ name: it.uUserName, value: it.id });
      });
      searchCols.value.forEach((it) => {
        if (it.key == "userids_ay") {
          it.data = ay;
        }
      });
    }
  });
};

let callbackFun;
let treeRef;

onMounted(() => {
  init();
  const PARENT_PROVIDE = "REPORT_EVENT";
  callbackFun = inject(PARENT_PROVIDE);
  treeRef = inject("treeRef");
});

const isCrossMonth = (dateStr1, dateStr2) => {
  // 将时间字符串解析为 moment 对象
  const moment1 = moment(dateStr1);
  const moment2 = moment(dateStr2);

  // 获取年份和月份
  const year1 = moment1.year();
  const month1 = moment1.month(); // 注意：月份从0开始（0=1月，11=12月）

  const year2 = moment2.year();
  const month2 = moment2.month();

  // 判断是否跨月
  return year1 !== year2 || month1 !== month2;
};

const callbackEvent = (obj) => {
  switch (obj.type) {
    case "change_time":
      console.log(obj);
      if (obj.evt == 1) {
        searchCols.value.forEach((it) => {
          if (it.key == "begintime") {
            it.val = moment().format("YYYY-MM-DD 00:00:00");
          } else if (it.key == "endtime") {
            it.val = moment().format("YYYY-MM-DD 23:59:59");
          }
        });
      } else if (obj.evt == 2) {
        searchCols.value.forEach((it) => {
          if (it.key == "begintime") {
            it.val = moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00");
          } else if (it.key == "endtime") {
            it.val = moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59");
          }
        });
      } else if (obj.evt == 3) {
        searchCols.value.forEach((it) => {
          if (it.key == "begintime") {
            it.val = moment()
              .startOf("week")
              .add(1, "day")
              .format("YYYY-MM-DD 00:00:00");
          } else if (it.key == "endtime") {
            it.val = moment()
              .endOf("week")
              .add(1, "day")
              .format("YYYY-MM-DD 23:59:59");
          }
        });
      } else if (obj.evt == 4) {
        searchCols.value.forEach((it) => {
          if (it.key == "begintime") {
            it.val = moment()
              .subtract(1, "weeks")
              .startOf("week")
              .add(1, "day")
              .format("YYYY-MM-DD 00:00:00");
          } else if (it.key == "endtime") {
            it.val = moment()
              .subtract(1, "weeks")
              .endOf("week")
              .add(1, "day")
              .format("YYYY-MM-DD 23:59:59");
          }
        });
      } else if (obj.evt == 5) {
        searchCols.value.forEach((it) => {
          if (it.key == "begintime") {
            it.val = moment().startOf("month").format("YYYY-MM-DD 00:00:00");
          } else if (it.key == "endtime") {
            it.val = moment().endOf("month").format("YYYY-MM-DD 23:59:59");
          }
        });
      } else if (obj.evt == 6) {
        searchCols.value.forEach((it) => {
          if (it.key == "begintime") {
            it.val = moment()
              .subtract(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD 00:00:00");
          } else if (it.key == "endtime") {
            it.val = moment()
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD 23:59:59");
          }
        });
      } else if (obj.evt == 7) {
        searchCols.value.forEach((it) => {
          if (it.key == "begintime") {
            it.val = moment().format("YYYY-MM-DD 00:00:00");
          } else if (it.key == "endtime") {
            it.val = moment().format("YYYY-MM-DD 23:59:59");
          }
        });
      }
      break;
    case "search":
      // query.devids = "";
      // let ay = toRaw(treeRef.value.getCheckedNodes());
      // let ay_dev = [];
      // ay.forEach((it) => {
      //   if (it.nodetype == 2) {
      //     ay_dev.push(it.vDeviceID);
      //   }
      // });
      // query.devids = ay_dev.join(",");
      // console.log(obj.search);
      // let ay_time = obj.search.times.split(",");
      // if (ay_time.length == 2) {
      //   query.begintime = ay_time[0];
      //   query.endtime = ay_time[1];
      // }
      query.begintime = obj.search.begintime;
      query.endtime = obj.search.endtime;

      const date1 = new Date(query.begintime.replace(" ", "T"));
      const date2 = new Date(query.endtime.replace(" ", "T"));
      if (date1 > date2) {
        ElMessageBox.alert(
          t("map.qingshuruzhengquedekaishishijianhejieshushijian")
        );
        return;
      }

      if (
        isCrossMonth(
          query.begintime.replace(" ", "T"),
          query.endtime.replace(" ", "T")
        )
      ) {
        ElMessageBox.alert(t("map.qbykycx"));
        return;
      }

      query.userids = obj.search.userids_ay;
      query.type = obj.search.type;
      query.login_type = obj.search.login_type;
      query.content_value = obj.search.content_value;

      getList();

      break;
    case "export":
      Object.assign(query, obj.search);
      proxy.$api.sys_vehicles_grid_excel(query).then((res) => {
        // 处理返回的文件流
        const content = res.data;
        const blob = new Blob([content], {
          type: "application/vnd.ms-excel;charset=utf-8",
        });
        var date =
          new Date().getFullYear() +
          "" +
          (new Date().getMonth() + 1) +
          "" +
          new Date().getDate() +
          "" +
          new Date().getHours() +
          "" +
          new Date().getMinutes() +
          "" +
          new Date().getSeconds() +
          "" +
          new Date().getMilliseconds();
        const fileName = tabsLabel.tabA + "." + "xlsx";
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
        // console.log(res);
      });
      break;
    case "download":
      location.href = process.env.VUE_APP_BASE_URL + "Public/car.xlsx";
      break;
  }
};

//处理tabs
const tabsLabel = reactive(Tools.tabsLabel);
const tabsName = ref("list");
const tabsActiveNameChange = (val) => {
  tabsName.value = val;
};
///////////////////
</script>

<style lang="scss" scoped></style>
